@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@200;400;700&display=swap");

:root {
  color-scheme: dark;
  --title: #44dbf8;
  --title_glow: #44dbf866;
  --lavender_primary: #b2acff;
  --lavender_highlight: #d5d1ff;
  --lavender_shadow: #6559ff;
  --lavender_glow: #b2acff70;
  --header_outline: #2c2a5199;
  --main-fill-light: #00030c43;
  --main-fill-dark: #00030ca5;
  --shade: #00030cd3;
}

span {
  display: inline-block;
}

a {
  color: var(--title);
  text-decoration: "underline";
}

/* svg {
  filter: drop-shadow(0px 0px 7px rgba(241, 218, 255, 0.222));
} */

/* canvas {
  display: block;
  vertical-align: bottom;
} */

/* #tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
} */

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #060513; */
  background-color: black;
  /* background-image: linear-gradient(
    165deg,
    #231536,
    #171e3d,
    #191933,
    #221122,
    #221122,
    #171e3d
  ); */

  /* background-image: linear-gradient(
    165deg,
    #0A091A,
    #0E0933,
    #140B23
  ); */
  /* background-size: 100% 150%;
  animation: bg-wobble 10s ease infinite; */
}

@keyframes bg-wobble {
  from {
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  to {
    background-position: 100% 0%;
  }
}

@keyframes intro-activate {
  from {
    background-position: 100% 100%;
  }
  to {
    background-position: 0% 0%;
  }
}

.social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--main-fill-dark);
  backdrop-filter: blur(8px);
  border: solid;
  border-top-width: 0;
  border-bottom-width: 0.1rem;
  border-left-width: 0.1rem;
  border-right-width: 0;
  border-color: var(--header_outline);
  position: fixed;
  top: 0;
  right: 0;
  border-radius: 0rem 0rem 0rem 1rem;
  padding: 0.4rem;
  animation: fadeInDownBig;
  /* animation-delay: 2500ms; */
  animation-fill-mode: both;
  animation-duration: 1s;
  z-index: 11;
}

.social svg {
  fill: var(--lavender_primary);
  transition: fill 250ms;
}

.social svg:hover {
  fill: var(--lavender_highlight);
}

.social-link {
  width: 48px;
  height: 48px;
  margin: 6px 6px 2px 6px;
}

.intro-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* transform: skewY(-5deg); */
  /* background-color: #060513; */
  /* background-image: url("images/profile.png"); */
  /* background-size: cover; */
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
  background-size: 400%, 400%;
  background-image: linear-gradient(
    165deg,
    #091824,
    #0d0924,
    #0e071d,
    black,
    black
  );
  /* padding-right: 5%;
  padding-left: 5%; */
  padding-bottom: 5%;
  padding-top: 5%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 95%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 95%, 0 100%);
  animation: intro-activate 3s ease;
}

.intro-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 6%;
  max-width: 1280px;
  /* max-width: 1920px; */
  /* background-color: white; */
  /* transform: skewY(5deg); */
}

/* .text-container {
  max-width: 1280px;
} */

.intro-profile {
  width: 30%;
  /* max-width: 50vh; */
  animation: fadeInLeft;
  animation-duration: 1s;
  animation-delay: 750ms;
  animation-fill-mode: both;
  /* margin-right: 4%; */
}

.intro-text h2 {
  color: var(--lavender_primary);
  text-shadow: 0 0 3vw var(--lavender_glow);
  font-size: 3.5vw;
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 400;
  text-align: left;
  margin: 0;
  animation: fadeInRight;
  animation-duration: 1s;
  /* animation-delay: 250ms; */
  animation-fill-mode: both;
  z-index: 1;
}

.intro-text h1 {
  color: var(--title);
  text-shadow: 0 0 3vw var(--title_glow);
  font-size: 8vw;
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 700;
  text-align: left;
  line-height: 6.5vw;
  margin: 0;
  margin-bottom: 1%;
  margin-top: 0%;
  animation: fadeInRight;
  animation-duration: 1s;
  animation-delay: 750ms;
  animation-fill-mode: both;
  z-index: 1;
}

.intro-text p {
  color: var(--lavender_primary);
  text-shadow: 0 0 3vw var(--lavender_glow);
  font-size: 2.4vw;
  line-height: 2.5vw;
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 400;
  text-align: left;
  margin: 0;
  animation: fadeInRight;
  animation-duration: 1s;
  animation-delay: 1750ms;
  animation-fill-mode: both;
  z-index: 1;
}

.fp-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -5rem;
  padding-top: 7rem;
  padding-bottom: 4rem;
  background-image: linear-gradient(165deg, #151742, #211036, #2c1026);
}

.fp-container {
  max-width: 50rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  /* margin-left: 2rem;
  margin-right: 2rem; */
  margin-top: 0rem;
  margin-bottom: 0rem;
  animation: fadeInUp;
  animation-duration: 1s;
  animation-delay: 2000ms;
  animation-fill-mode: both;
  /* padding-left: 5rem; */
}

.fp-key-image {
  background-color: var(--lavender_primary);
  box-shadow: -0.2rem 0rem 1.5rem 0.05rem var(--lavender_glow);
  border-radius: 1rem;
  border: solid;
  border-top-width: 0rem;
  border-left-width: 0rem;
  border-right-width: 0.5rem;
  border-bottom-width: 0rem;
  border-color: var(--lavender_shadow);
  max-height: 20rem;
  max-width: 24rem;
  padding: 0.2rem;
  margin-right: -5rem;
  z-index: 0.1;
  transform: perspective(50rem) rotateY(-45deg) rotateX(5deg);
  transition: transform 250ms, border-right-width 250ms, margin-right 250ms,
    box-shadow 250ms;
}

.fp-key-image:hover {
  transform: perspective(50rem) rotateY(0deg) rotateX(0deg);
  border-right-width: 0rem;
  margin-right: -4.5rem;
  box-shadow: 0rem 0rem 1.5rem 0.05rem var(--lavender_primary);
}

.fp-key-image img {
  padding: 1rem;
}

.fp-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background-image: linear-gradient(
    165deg,
    var(--main-fill-light),
    var(--main-fill-dark) 50%
  );
  padding: 1rem;
  border: solid;
  border-width: 0.1rem;
  /* border-radius: 0rem 1rem 1rem 0rem; */
  border-radius: 1.5rem;
  border-color: var(--header_outline);
  padding-left: 4rem;
  /* width: 40rem; */
  max-width: 40rem;
  /* height: 20rem; */
}

.fp-mobile-key-image {
  display: none;
}

.fp-description-container h1 {
  font-size: 3rem;
  line-height: 0.8em;
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.fp-description-container p {
  font-size: 1.3rem;
  line-height: 1.5rem;
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 400;
  margin: 0.5rem;
  margin-bottom: 1rem;
}

/* Found in the SVG files themselves. */
.fp-logo {
  /* margin-bottom: 1%; */
  /* height: 4rem; */
  max-width: 20rem;
  /* max-height: 150px; */
}

.fp-description {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  /* overflow: hidden; */
}

.tool-icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* margin-top: 0.5rem;
  margin-bottom: 0.5rem; */
}

.tool-icon {
  height: 4rem;
  max-height: 120px;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.tool-divider {
  border-radius: 1rem;
  width: 0.15rem;
  height: 3rem;
  max-height: 3.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

h3 {
  font-size: 5vw;
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 700;
  color: var(--lavender_primary);
  text-shadow: 0 0 3vw var(--lavender_glow);
  letter-spacing: 1vw;
  margin-top: 6vw;
  margin-bottom: 3vw;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: solid;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-color: #45556c27;
  width: 100%;
  padding-top: 0.1rem;
  background-color: var(--main-fill-dark);
  color: var(--lavender_primary);
  animation: fadeInUpBig;
  animation-duration: 1s;
}

.footer p {
  font-size: 40px;
  font-family: "Bai Jamjuree", sans-serif;
  margin: 0;
  padding-right: 1rem;
  padding-bottom: 0.2vw;
}

.footer svg {
  fill: var(--lavender_primary);
  transition: fill 250ms;
}

.footer svg:hover {
  fill: var(--lavender_highlight);
}

.shade-screen {
  /* display: block; */
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* align-content: center; */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* margin: 2rem; */
  background-color: var(--shade);
  backdrop-filter: blur(8px);
  animation: fadeIn;
  animation-duration: 500ms;
  z-index: 10;
}

.modal-section {
  display: flex;
  flex-direction: column;
  justify-content: left;
  flex: 1;
  width: 100%;
  height: 80%;
  max-width: 2100px;
  max-height: 1200px;
  margin: 6rem 2rem 4rem 2rem;
  background-image: linear-gradient(
    165deg,
    var(--main-fill-light),
    var(--main-fill-dark) 50%
  );
  padding: 1rem;
  border: solid;
  border-width: 0.1rem;
  border-radius: 1.5rem;
  border-color: var(--header_outline);
  animation: fadeInUpBig;
  animation-duration: 500ms;
}

.modal-container {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: 100%;
}

.modal-text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  min-width: 0;
  margin-left: 2rem;
  margin-top: -2rem;
  /* padding: 1rem; */
}

/* .modal-text-container p,
a,
br {
  min-width: 0;
  flex-shrink: 0;
} */

.modal-text-container h1 {
  font-size: 4vw;
  line-height: 0.8em;
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 0;
  min-width: 0;
}

.modal-text-container p {
  font-size: 1.3rem;
  line-height: 1.5rem;
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 400;
  margin: 0.5rem;
  margin-bottom: 1rem;
}

.modal-text-container ul {
  padding-left: 1rem;
}

.modal-text-container li {
  text-align: left;
  font-size: 2.8vh;
  line-height: 2.8vh;
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 400;
  /* margin: 0.5rem; */
  margin-bottom: 0.8rem;
}

.modal-text-container .fp-logo {
  max-width: 30rem;
  min-width: 0;
  margin-left: -0.75rem;
}

.modal-text-container .tool-icon-container {
  min-width: 0;
  margin-left: -0.75rem;
}

.modal-text-container .tool-icon {
  height: 4vw;
  /* min-height: 1rem; */
  max-height: 5rem;
  /* min-width: 0.5rem; */
  /* max-width: 3rem; */
  /* max-height: 120px; */
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.modal-text-container .tool-divider {
  border-radius: 1rem;
  min-width: 2px;
  height: 2.5rem;
  max-height: 3.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.close-button {
  box-sizing: unset;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  line-height: -2rem;
  text-align: center;
  padding: 0;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 10rem;
  border-style: none;
  background-color: var(--header_outline);
  color: var(--lavender_primary);
  min-width: 2rem;
  min-height: 2rem;
  width: 2rem;
  height: 2rem;
  max-width: 2rem;
  z-index: 20;
}

.modal-lightbox {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
  max-width: 100%;
  max-height: 100%;
  /* OFFSETS THE IMAGE BORDER */
  padding-bottom: 4.4rem;
}

.modal-lightbox-big-img {
  min-width: 0;
  max-width: 100%;
  max-height: 100%;
  /* OFFSETS THE IMAGE BORDER*/
  padding-right: 0.4rem;
}

.modal-lightbox-big-img img {
  min-width: 0;
  box-shadow: 0rem 0rem 1rem 0.05rem var(--lavender_glow);
  border-radius: 1rem;
  border: solid;
  border-width: 0.2rem;
  border-color: var(--lavender_shadow);
  max-width: 100%;
  max-height: 100%;
}

/* Squarish Optimizations (iPad, Tablet) */
@media screen and (max-width: 900px) {
  .modal-lightbox {
    flex: 1;
  }

  .modal-text-container {
    margin-left: 1.5rem;
  }

  .modal-text-container h1 {
    font-size: 5vw;
  }

  .modal-text-container li {
    font-size: 2.2vh;
    line-height: 2.3vh;
    margin-bottom: 1rem;
  }
}

/* Mobile Optimizations */
@media screen and (max-width: 640px) {
  .social {
    display: none;
  }

  .intro-section {
    padding: 8% 0% 8% 0%;
  }

  .intro-profile {
    margin-top: -12%;
    /* margin-left: -2.5%; */
  }

  .intro-text h2 {
    font-size: 4vw;
  }

  .intro-text h1 {
    font-size: 11vw;
    line-height: 10vw;
    margin: 0;
    margin-bottom: 2%;
    margin-top: 1%;
  }

  .intro-text p {
    font-size: 3.5vw;
    line-height: 3.4vw;
  }

  .fp-description-container {
    padding: 0.8rem;
  }

  /* Found in the SVG files themselves. */
  .fp-logo {
    max-width: 16rem;
  }

  .fp-key-image {
    display: none;
  }

  .fp-mobile-key-image {
    display: inline;
    background-color: var(--lavender_primary);
    border-color: var(--lavender_shadow);
    border-radius: 0.75rem;
    border: solid;
    border-top-width: 0rem;
    border-left-width: 0rem;
    border-bottom-width: 0rem;
    border-right-width: 0.35rem;
    box-shadow: 0rem 0rem 1.5rem 0.05rem var(--lavender_glow);
    aspect-ratio: auto;
    max-height: 15rem;
    max-width: 15rem;
    padding: 0.2rem;
    margin-bottom: 0.8rem;
    margin-top: 0.5rem;
    /* margin-right: -5rem; */
    transform: perspective(50rem) rotateY(-30deg) rotateX(5deg);
    transition: transform 250ms, border-right-width 250ms, box-shadow 250ms;
    z-index: 0.1;
  }

  .fp-mobile-key-image:hover {
    transform: perspective(50rem) rotateY(0deg) rotateX(0deg);
    border-right-width: 0rem;
    box-shadow: 0rem 0rem 1.5rem 0.05rem var(--lavender_primary);
  }

  .fp-description-container p {
    font-size: 1.2rem;
    margin: 0.5rem;
    /* margin-bottom: 1rem; */
  }

  .fp-description-container h1 {
    font-size: 2.5rem;
    line-height: 0.8em;
  }

  /* .tool-icon-container {
    margin-bottom: 2%;
  } */

  .tool-icon {
    height: 9vw;
    margin-left: 0.8vw;
    margin-right: 0.8vw;
  }

  .tool-divider {
    width: 0.5vw;
    height: 7vw;
    margin-left: 1.6vw;
    margin-right: 1.6vw;
  }

  h3 {
    font-size: 6.8vw;
  }

  .modal-section {
    margin-bottom: 10rem;
  }

  .modal-container {
    flex-direction: column;
  }

  .modal-text-container {
    padding: 0.5rem;
    margin-left: 0rem;
  }

  .modal-text-container .fp-logo {
    margin-left: 0rem;
  }

  .modal-text-container h1 {
    font-size: 10vw;
  }

  .modal-text-container p {
    font-size: 4vw;
    line-height: 4.2vw;
    margin-bottom: 2vw;
  }

  .modal-text-container li {
    font-size: 4vw;
    line-height: 4.2vw;
    /* margin: 0.5rem; */
    margin-bottom: 0.5rem;
  }

  .modal-text-container .tool-icon-container {
    margin-left: 0rem;
  }

  .modal-text-container .tool-icon {
    height: 9vw;
    margin-left: 0.8vw;
    margin-right: 0.8vw;
  }

  .modal-text-container .tool-divider {
    width: 0.5vw;
    height: 7vw;
    margin-left: 1.6vw;
    margin-right: 1.6vw;
  }

  .modal-lightbox {
    /* Margin and Padding preserve glow effect when hiding Overflow */
    margin: -1rem;
    padding: 1rem;
    overflow: hidden;
    flex: 1;
    padding-bottom: 8vh;
  }

  .close-button {
    margin-bottom: 1rem;
    border-style: none;
  }

  /* .modal-text-container {
    flex: 0 1;
  } */

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: solid;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
    border-color: var(--header_outline);
    width: 100%;
    padding-top: 0.2vw;
    background-color: var(--main-fill-dark);
    backdrop-filter: blur(8px);
    border-top-width: 0.1rem;
    z-index: 11;
    position: fixed;
    bottom: 0;
  }

  .footer p {
    font-size: 7vw;
    padding-right: 0.25rem;
    /* padding-right: 2vh; */
  }

  .social-link {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0.25rem 0.25rem 0.125rem 0.25rem;
  }
}

/* Widescreen Optimizations */
@media screen and (min-width: 1280px) {
  .social {
    right: 15%;
    border-radius: 0rem 0rem 1rem 1rem;
    border-right-width: 0.1rem;
  }

  .intro-text h2 {
    font-size: 3vw;
  }

  .intro-text p {
    font-size: 32px;
    line-height: 30px;
  }

  .modal-text-container li {
    font-size: 1.5rem;
    line-height: 1.6rem;
  }

  .modal-text-container .tool-divider {
    height: 4rem;
  }

  /* header .social-link {
    width: 60px;
    height: 60px;
    margin: 6px;
  } */
}

/* Ultrawide Optimizations */
@media screen and (min-width: 2100px) {
  .intro-text h1 {
    font-size: 150px;
    line-height: 120px;
  }

  .intro-text h2 {
    font-size: 60px;
  }

  .intro-text p {
    font-size: 40px;
    line-height: 42px;
  }

  .intro-profile {
    width: 20vw;
  }

  h3 {
    font-size: 72px;
    margin-top: 128px;
    margin-bottom: 48px;
  }

  .modal-text-container h1 {
    font-size: 72px;
    line-height: 64px;
  }

  .modal-text-container p {
    font-size: 28px;
    line-height: 26px;
  }

  .footer p {
    font-size: 56px;
  }
}
